import { bus } from 'src/plugins/bus'
import { getError, getPdfApiErrorMessage } from 'src/lib/error_message_helper'

const getDefaultState = () => ({
  errors: [],
  errorCode: undefined,
  errorMessage: undefined,
  redirectUrl: undefined,
  rawResponse: undefined
})

const state = getDefaultState()

const getters = {
  getErrors: state => {
    return [...state.errors]
  },
  getErrorMessage: state => {
    return state.errorMessage
  },
  getErrorCode: state => {
    return state.errorCode
  },
  getError: state => {
    return {
      errorMessage: state.errorMessage,
      errorCode: state.errorCode,
      redirectUrl: state.redirectUrl
    }
  },
  getJsonErrors: state => {
    if (state.errors.length > 0) {
      let errors = ''

      for (let err of state.errors) {
        errors += `${JSON.stringify(err)}\n\n`
      }

      return errors
    }

    return undefined
  }
}

const actions = {
  handleError({ commit, getters }, error = {}) {
    const response = error.response || {}
    const expectedError = getError(response)
    const rawResponse = response.data || undefined
    const silent = response.silent ? true : false

    try {
      if (response.type === 'pdf') {
        expectedError.errorMessage = getPdfApiErrorMessage({ response })
      }

      commit('addError', { ...expectedError, rawResponse })

      if (!silent) {
        bus.emit('error', getters['getErrorMessage'])
      }
    } catch (e) {
      console.error('Error in Response Body and extracting UserMessage: ', e)
    }
  }
}

const mutations = {
  addError(state, error) {
    Object.assign(state, {
      ...error,
      errors: [...state.errors, error]
    })
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'apiErrors',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
