import { landingPages } from './landing_pages/landing_pages'
import brand from '@brand'

const portalPath = '/'

let dynamicPageRoutes

if (brand.code === 'fonic') {
  dynamicPageRoutes = [
    {
      path: portalPath + 'datenschutz-facebook',
      name: 'PrivacyFacebook',
      meta: {
        title: `- Datenschutzerklärung der ${brand.name} Fanpage auf Facebook`,
        layout: 'portal',
        type: 'portal',
        allowIndexing: true
      },
      component: () =>
        import('src/components/portal/privacy/privacy_facebook.vue')
    },
    {
      path: portalPath,
      name: 'PortalHome',
      meta: {
        title: 'Login',
        layout: 'standalone',
        showCancellationLink: true
      },
      props: route => ({
        redirectTo: route.query.redirect,
        reason: route.query.reason
      }),
      component: () =>
        import('src/components/selfcare/authentication/login.vue')
    }
  ]
} else if (brand.code === 'fonic-mobile') {
  dynamicPageRoutes = [
    {
      path: portalPath,
      name: 'PortalHome',
      meta: {
        layout: 'portal',
        type: 'portal',
        allowIndexing: true,
        isCanonical: true,
        skipLinkToStage: true
      },
      component: () => import('src/components/portal/home/home.vue')
    },
    {
      path: portalPath + 'willkommen',
      name: 'PortalWelcomePage',
      meta: {
        title: 'Herzlich Willkommen!',
        layout: 'promo',
        type: 'portal',
        background: 'gradient',
        allowIndexing: false
      },
      component: () =>
        import('src/components/portal/welcome_page/welcome_page.vue')
    },
    {
      path: portalPath + 'willkommen/:app',
      name: 'PortalAppWelcomePage',
      meta: {
        title: 'Herzlich Willkommen!',
        layout: 'promo',
        type: 'portal',
        background: 'gradient',
        allowIndexing: false,
        standalone: true
      },
      component: () =>
        import('src/components/portal/welcome_page/welcome_page.vue')
    },
    {
      path: portalPath + 'fonic-fakten',
      name: 'PortalFonicFacts',
      meta: {
        title: 'FONIC mobile wird zu FONIC',
        layout: 'portal',
        type: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () =>
        import('src/components/portal/goodbye_details/goodbye_details.vue')
    },
    {
      path: portalPath + 'prepaid-tarife',
      name: 'PortalTariffOverview',
      meta: {
        title: '- Tarifübersicht, Smartphone Tarife und mobiles Internet',
        layout: 'portal',
        type: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () =>
        import('src/components/portal/tariff/overview/overview.vue')
    },
    {
      path: portalPath + 'prepaid-tarife/mobiles-internet',
      name: 'MobileInternetLandingPage',
      meta: {
        title: 'Themen',
        layout: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () =>
        import('src/components/portal/mobile_internet/mobile_internet.vue')
    },
    {
      path: portalPath + 'prepaid-tarife/fuer-kinder',
      name: 'PrepaidForKidsLandingPage',
      meta: {
        title: '- Prepaid Karte für Kinder',
        layout: 'portal',
        type: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () =>
        import('src/components/portal/prepaid_for_kids/prepaid_for_kids.vue')
    },
    {
      path: portalPath + 'prepaid-tarife/senioren',
      name: 'PrepaidForSeniorsLandingPage',
      meta: {
        title: '- Prepaid Karte für Senioren | Günstig sichern bei FONIC',
        layout: 'portal',
        type: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () =>
        import(
          'src/components/portal/prepaid_for_seniors/prepaid_for_seniors.vue'
        )
    },
    {
      path: portalPath + 'prepaid-tarife/schueler-und-studenten',
      name: 'PrepaidForStudentsLandingPage',
      meta: {
        title: '- Prepaid Tarife für Schüler & Studenten | Jetzt bei FONIC',
        layout: 'portal',
        type: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () =>
        import(
          'src/components/portal/prepaid_for_students/prepaid_for_students.vue'
        )
    },
    {
      path:
        portalPath +
        'prepaid-tarife/prepaid-ausland/:tariffId?/:originCountry?/:destinationCountry?',
      name: 'PrepaidAbroadLandingPage',
      meta: {
        title: '- Prepaid im Ausland',
        layout: 'portal',
        type: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () =>
        import('src/components/portal/prepaid_abroad/prepaid_abroad.vue')
    },
    {
      path: portalPath + 'prepaid-tarife/internet-im-wohnmobil',
      name: 'PrepaidCampingLandingPage',
      meta: {
        title: '- Internet im Wohnmobil',
        layout: 'portal',
        type: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () =>
        import('src/components/portal/prepaid_camping/prepaid_camping.vue')
    },
    {
      path: portalPath + 'prepaid-tarife/handyvertrag-trotz-schufa',
      name: 'PrepaidSchufaLandingPage',
      meta: {
        title: '- Handyvertrag trotz SCHUFA sichern',
        layout: 'portal',
        type: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () =>
        import('src/components/portal/prepaid_schufa/prepaid_schufa.vue')
    },
    {
      path: portalPath + 'prepaid-tarife/:slug',
      name: 'PortalTariffDetails',
      meta: {
        layout: 'portal',
        allowIndexing: true,
        background: 'grey',
        isCanonical: true
      },
      component: () => {
        return import('src/components/portal/tariff/details/details.vue')
      }
    },
    {
      path: portalPath + 'prepaid-handys-tablets',
      name: 'PrepaidHardwareLandingPage',
      meta: {
        title: 'Themen',
        layout: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () =>
        import('src/components/portal/prepaid_hardware/prepaid_hardware.vue')
    },
    {
      path: portalPath + '/prepaid-handys-tablets/iphone-ohne-vertrag',
      name: 'SmartphonePageIphone',
      meta: {
        title: '– iPhone ohne Vertrag',
        layout: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () =>
        import('src/components/portal/smartphone_pages/apple/iphone.vue')
    },
    {
      path: portalPath + '/prepaid-handys-tablets/samsung-handy-ohne-vertrag',
      name: 'SmartphonePageSamsung',
      meta: {
        title: '– Samsung ohne Vertrag',
        layout: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () =>
        import('src/components/portal/smartphone_pages/samsung/samsung.vue')
    },
    {
      path: portalPath + 'netz',
      name: 'NetworkRecoloring',
      meta: {
        title: '',
        layout: 'portal',
        type: 'portal',
        allowIndexing: true
      },
      component: () => import('src/components/portal/network/recoloring.vue')
    }
  ]

  for (const landingPage of landingPages) {
    dynamicPageRoutes.push({
      path: landingPage.path,
      name: landingPage.name,
      meta: {
        title: 'Themen',
        layout: 'portal',
        slug: landingPage.slug,
        allowIndexing: true,
        isCanonical: true
      },
      component: () =>
        import('src/components/portal/landing_pages/landing_pages.vue')
    })
  }
}

const routes = [
  {
    path: portalPath + 'sc_lowbalance',
    name: 'LowBalance',
    meta: {
      layout: 'standalone',
      allowIndexing: true
    },
    component: () => import('src/components/portal/sc/low_balance.vue')
  },
  {
    path: portalPath + 'sc_activationinfo',
    name: 'ActivationInfo',
    meta: {
      layout: 'standalone',
      allowIndexing: true
    },
    component: () => import('src/components/portal/sc/activation_info.vue')
  },
  {
    path: portalPath + 'impressum',
    name: 'PortalImprint',
    meta: {
      title: 'Impressum',
      layout: 'portal',
      type: 'portal',
      allowIndexing: true,
      isCanonical: true
    },
    component: () => import('src/components/portal/imprint/imprint.vue')
  },
  {
    path: portalPath + 'infopflicht',
    name: 'PortalLegalInformation',
    meta: {
      title: 'Informationspflichten',
      layout: 'portal',
      type: 'portal',
      allowIndexing: true,
      isCanonical: true
    },
    component: () =>
      import('src/components/portal/legal_information/legal_information.vue')
  },
  {
    path: portalPath + 'datenschutz',
    name: 'Privacy',
    meta: {
      title: '- Datenschutzerklärung',
      layout: 'portal',
      type: 'portal',
      allowIndexing: true,
      isCanonical: true
    },
    component: () => import('src/components/portal/privacy/privacy.vue')
  },
  {
    path: portalPath + 'widerrufsbelehrung',
    name: 'CancellationPolicy',
    meta: {
      title: '- Widerrufsbelehrung',
      layout: 'portal',
      type: 'portal',
      allowIndexing: true
    },
    component: () =>
      import(
        'src/components/portal/cancellation_policy/cancellation_policy.vue'
      )
  },
  {
    path: portalPath + 'weitersurfen',
    name: 'ZeroRating',
    meta: {
      title: '- Weiter surfen',
      layout: 'standalone',
      type: 'portal',
      allowIndexing: false
    },
    component: () => import('src/components/portal/zero_rating/zero_rating.vue')
  },
  {
    path: portalPath + 'agb-und-infos',
    name: 'AGBAndInfo',
    meta: {
      title: '- AGB & Infos',
      layout: 'portal',
      type: 'portal',
      allowFollow: true,
      isCanonical: true
    },
    component: () => import('src/components/portal/agb/agb_and_info.vue')
  },
  {
    path: portalPath + 'cookies',
    name: 'Cookies',
    meta: {
      title: '- Cookies',
      description:
        'Hier erfährst du mehr über unsere Cookie Einstellungen und Verarbeitung der Informationen.',
      layout: 'portal',
      type: 'portal',
      allowFollow: true,
      isCanonical: true
    },
    component: () =>
      import('src/components/portal/cookie_consent/cookie_consent.vue')
  }
]

export default dynamicPageRoutes.concat(routes)
