import { debounce } from 'debounce'
import { loadGtm } from './google_tag_manager'
import { loadHotjar } from './hotjar.js'
import { loadVwo } from './visual_website_optimizer'
import brand from '@brand'

const initUsercentrics = (app, settingsId) => {
  const ucScript = document.createElement('script')

  ucScript.async = 1
  ucScript.src = 'https://app.usercentrics.eu/latest/main.js'
  ucScript.id = settingsId
  ucScript.type = 'application/javascript'
  ucScript.onload = () => {
    const debouncedPageReload = debounce(function () {
      location.reload()
    }, 200)

    const debouncedExecScripts = debounce(function () {
      if (window && window['usercentrics']) {
        window.usercentrics.executeConsentScripts()
      }
    }, 200)

    window.addEventListener('ucConsentChanged', function (e) {
      if (e.data.event === 'consent_changed') {
        const service = Object.keys(e.data).find(key => key !== 'event')

        if (service in e.data && !e.data[service]) {
          debouncedPageReload()
        } else if (service in e.data && e.data[service]) {
          if (service === 'Visual Website Optimizer (VWO)') {
            loadService(VWO_SERVICE_ID, loadVwo)
          } else if (service === 'Hotjar') {
            loadServiceHotjar()
          }

          debouncedExecScripts()
        }
      }
    })
  }

  window['usercentrics'] = window['usercentrics'] || {}

  const loadService = (serviceID, callback) => {
    if (window.usercentrics.getConsents(serviceID).consentStatus) {
      callback()
    }
  }

  const loadServiceHotjar = () => {
    if (brand.hotjarId) {
      loadService(HOTJAR_SERVICE_ID, loadHotjar)
    }
  }

  const VWO_SERVICE_ID = 'SJDM9Niuo-X'
  const HOTJAR_SERVICE_ID = 'S1kgcNo_j-m'
  const GTM_SERVICE_ID = 'BJ59EidsWQ'

  if (window['usercentrics'].isInitialized) {
    loadService(GTM_SERVICE_ID, loadGtm)
    loadService(VWO_SERVICE_ID, loadVwo)
    loadServiceHotjar()
  } else {
    window['usercentrics'].onViewInit = function () {
      loadService(GTM_SERVICE_ID, loadGtm)
      loadService(VWO_SERVICE_ID, loadVwo)
      loadServiceHotjar()
    }
  }

  document.getElementsByTagName('head')[0].appendChild(ucScript)
}

const loadUsercentrics = app => {
  if (brand.usercentricsSettingsId) {
    initUsercentrics(app, brand.usercentricsSettingsId)
  }
}

export { loadUsercentrics }
