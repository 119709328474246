import brand from '@brand'

const baseUrl = import.meta.env.BASE_URL || '/'
const servicePath = baseUrl + 'service'

let dynamicPageRoutes

if (brand.code === 'fonic') {
  dynamicPageRoutes = []
} else if (brand.code === 'fonic-mobile') {
  dynamicPageRoutes = [
    {
      path: servicePath,
      name: 'PortalServiceHome',
      meta: {
        title: 'Service',
        layout: 'portal',
        description: `Der ${brand.name} Kundenservice: Fragen &amp; Antworten (FAQ), ${brand.name} App, Downloads &amp; Formulare, Verkaufsstellen sowie der direkte Kontakt zur ${brand.name} Hotline.`,
        allowIndexing: true,
        isCanonical: true
      },
      component: () => import('src/components/portal/service/home.vue')
    },
    {
      path: servicePath + `/ueber-${brand.code}`,
      name: 'PortalAbout',
      meta: {
        title: `Vorteile - Alle Informationen und Tests rund um ${brand.name}`,
        description: `Mit unseren Informationen rund um ${brand.name} bleiben Sie stets auf dem Laufenden: Vorteile ✓ Auszeichnungen ✓  Geld zurück Garantie ✓`,
        layout: 'portal',
        type: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () => import('src/components/portal/service/about/about.vue')
    },
    {
      path: servicePath + `/ueber-${brand.code}/verkaufsstellen`,
      name: 'PortalServiceVoucherShops',
      meta: {
        title: 'Partner und Verkaufsstellen',
        layout: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () =>
        import('src/components/portal/service/voucher_shops/voucher_shops.vue')
    },
    {
      path: servicePath + '/faq/technik/netzabdeckung',
      name: 'CellularCoverage',
      meta: {
        title: `Netz-Abdeckung und -Störungen`,
        description: `${brand.name} - alle Fragen und Antworten zum Thema Netzabdeckung von Telefónica!`,
        layout: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () =>
        import(
          'src/components/portal/service/cellular_coverage/cellular_coverage.vue'
        )
    },
    {
      path: servicePath + '/faq/:category?/:subcategory?',
      name: 'PortalServiceFaq',
      meta: {
        title: 'FAQs',
        layout: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () => import('src/components/portal/service/faq/faqs.vue')
    },
    {
      path: servicePath + `/${brand.code}-app`,
      name: 'PortalServiceApp',
      meta: {
        title: `/${brand.name} App`,
        layout: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () => import('src/components/portal/service/app/app.vue')
    },
    {
      path: servicePath + '/kontakt',
      name: 'PortalServiceContact',
      meta: {
        title: 'Kontakt',
        layout: 'portal',
        type: 'portal',
        allowIndexing: true,
        isCanonical: true
      },
      component: () => import('src/components/portal/contact/contact.vue')
    }
  ]
}

const routes = [
  {
    path: servicePath + `/ueber-${brand.code}/alle-awards`,
    name: 'PortalAwards',
    meta: {
      title: `Vorteile - Alle Tests und Auszeichnungen rund um ${brand.name}`,
      description: `Mit unseren Informationen rund um ${brand.name} bleibst du stets auf dem Laufenden: Tests ✓ Auszeichnungen ✓`,
      layout: 'portal',
      type: 'portal',
      allowIndexing: true,
      isCanonical: true
    },
    component: () =>
      import(
        'src/components/portal/service/about/awards_overview/awards_overview.vue'
      )
  },
  {
    path: servicePath + '/formulare',
    name: 'PortalServiceDownloads',
    meta: {
      title: `Formulare und Downloads`,
      layout: 'portal',
      allowFollow: true,
      isCanonical: true
    },
    component: () =>
      import('src/components/portal/service/downloads/downloads.vue')
  }
]

export default dynamicPageRoutes.concat(routes)
