import brand from '@brand'

const fonicCalculatorPath = '/selfcare/service/roaming-calculator'
const lidlCalculatorPath = '/service/roaming-calculator'
const roamingCalculatorPath =
  brand.code === 'fonic-mobile' ? lidlCalculatorPath : fonicCalculatorPath

export default [
  {
    path:
      roamingCalculatorPath +
      '/:tariffId?/:originCountry?/:destinationCountry?',
    name: 'RoamingCalculator',
    meta: {
      title: 'Roaming-Rechner',
      layout: 'portal',
      allowFollow: true,
      isCanonical: true
    },
    component: () =>
      import(
        'src/components/portal/service/roaming_calculator/roaming_calculator.vue'
      )
  }
]
